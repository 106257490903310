import React, { useState, useMemo,useEffect } from 'react';
import {
    Calendar,
    CheckCircle,
    XCircle,
    PageRight,
    EditPencil,
    Trash,
    Search,
    PageLeft
  } from "iconoir-react";
import MainLayout from '../../layout/main';
import AddNewCoupon from '../../component/AddNewCoupen';
import DialogBox from '../../component/DialogBox';
import { fetchCoupon,addCoupon,deleteCoupon} from "../../services/admin.service";
import { useQuery,useMutation,useQueryClient } from '@tanstack/react-query';

const AdminCouponsDashboard = () => {
  const queryClient = useQueryClient();
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "auto";
    };
}, []);

const query = {
    category: "coupon",
};

const { data: coupons, isLoading: loadCoupons } = useQuery({
    queryKey: ['coupons',query],
    queryFn: async () => {
        const response = await fetchCoupon();
        console.log("------------",response)
        return response
    },
    staleTime: Infinity,
    retry: false
});

console.log("---data----",coupons)

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const sortCoupons = (a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredCoupons = useMemo(() => {
    return coupons?.coupons
      ?.filter(coupon => 
        coupon.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        coupon.offer_id.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort(sortCoupons);
  }, [coupons, searchTerm, sortField, sortDirection]);

  const pageCount = Math.ceil(filteredCoupons?.length / itemsPerPage);
  const paginatedCoupons = filteredCoupons?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const [showAddCoupon, setShowAddCoupon] = useState(false);

 

  const { mutate: mututeAddCoupon, isPending: isPendingCoupon } = useMutation({
    mutationFn: addCoupon,
    onSuccess: () => {
      setShowAddCoupon(false);  
      queryClient.invalidateQueries('coupons');  
    },
    onError: (error) => {
        console.log("error",error)
    }
});

const handleAddCoupon = (newCouponData) => {
  // Here you would typically send this data to your backend API
  console.log('New Coupon Data:', newCouponData);
  mututeAddCoupon(newCouponData);

};




  const handleDelete = (id) => {
    console.log('Deleting Coupon ID:', id);
    mututedeleteCoupon(id)
  };

  const { mutate: mututedeleteCoupon, isPending: isDeleteCoupon } = useMutation({
    mutationFn: deleteCoupon,
    onSuccess: () => {
      console.log("success")
      queryClient.invalidateQueries('coupons');
    },
    onError: (error) => {
        console.log("error",error)
    }
});

  return (
    <MainLayout>
    <div className="container mx-auto px-4 py-8 overflow-hidden">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Coupons Dashboard</h1>
      <div className="mb-4 flex justify-between  ">
        
      {!showAddCoupon && (
               <div className="relative">
               <input
                 type="text"
                 placeholder="Search coupons..."
                 className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                 value={searchTerm}
                 onChange={(e) => setSearchTerm(e.target.value)}
               />
               <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
             </div>
      )}
        {!showAddCoupon && (
        <button 
          onClick={() => setShowAddCoupon(true)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Add New Coupon
        </button>
      )}
      {showAddCoupon && (
        <DialogBox>
        <AddNewCoupon onSubmit={handleAddCoupon} onClose={()=>{setShowAddCoupon(false)}} />
        </DialogBox>
      )}
      </div>
     

      <div className="bg-white shadow-md rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {['Name', 'Discount', 'Valid From', 'Valid Till', 'Usage', 'Status', 'Actions'].map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort(header.toLowerCase().replace(' ', ''))}
                  >
                    {header}
                    {sortField === header.toLowerCase().replace(' ', '') && (
                      <span className="ml-1">{sortDirection === 'asc' ? '▲' : '▼'}</span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {paginatedCoupons?.map((coupon) => (
                <tr key={coupon._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{coupon.name}</div>
                    <div className="text-sm text-gray-500">{coupon.offer_id}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{coupon.discountPercentage}%</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{formatDate(coupon.validFrom)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{formatDate(coupon.validTill)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{coupon.usedCount} / {coupon.usageLimit}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      coupon.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {coupon.isActive ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    {/* <button className="text-indigo-600 hover:text-indigo-900 mr-2">
                      <EditPencil className="h-5 w-5" />
                    </button> */}
                    <button className="text-red-600 hover:text-red-900" onClick={()=>{handleDelete(coupon?._id)}}>
                      <Trash className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {pageCount > 1 && (
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => setCurrentPage(page => Math.min(page + 1, pageCount))}
                disabled={currentPage === pageCount}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to <span className="font-medium">{Math.min(currentPage * itemsPerPage, filteredCoupons.length)}</span> of{' '}
                  <span className="font-medium">{filteredCoupons.length}</span> results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Previous</span>
                    <PageLeft className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {[...Array(pageCount)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentPage(index + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                        currentPage === index + 1
                          ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                          : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                      }`}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => setCurrentPage(page => Math.min(page + 1, pageCount))}
                    disabled={currentPage === pageCount}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Next</span>
                    <PageRight className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    </MainLayout>
  );
};

export default AdminCouponsDashboard;

