import { toast } from "react-toastify";
import HeartAndThumbIcon from "../asset/svg/HeartAndThumbIcon";
import { formatToK } from "../utils/formatter";
import { deleteMedia } from "../services/trending.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const MediaCard = (props) => {
    const {
        _id,
        totalLikeCount,
        mediaType,
        mediaURL,
        topicGroupId,
        isShareworthy,
        isFunny,
        isInsightful,
        source
    } = props;
    const queryClient = useQueryClient();

    const { mutate, isPending } = useMutation({
        mutationFn: deleteMedia,
        onSuccess: () => {
            toast.success("Deleted successfully");
            queryClient.invalidateQueries({ queryKey: ['trending-media'] })

        },
        onError: () => {
            toast.error('Something went wrong');
        },
    });


    const handleDelete = () => {
        mutate(_id)
    }
    return (
        <div className="min-w-[360px]">
            <div className="p-6 rounded-xl bg-gray-100 shadow-lg hover:shadow-xl transition-shadow duration-300">
                {/* Media Section */}
                {mediaType === "video" && (
                    <div className="h-[228px] rounded-lg overflow-hidden my-4 bg-black">
                        <video
                            src={mediaURL}
                            controlsList="nodownload"
                            controls
                            className="w-full h-full"
                        />
                    </div>
                )}
                {mediaType === "image" && (
                    <div className="h-[228px] rounded-lg overflow-hidden my-4 bg-gray-200 flex items-center justify-center">
                        <img
                            src={mediaURL}
                            alt="Media"
                            className="w-full h-full object-contain"
                        />
                    </div>
                )}

                {/* Details Section */}
                <div className="flex justify-between items-center my-4">
                    {/* Likes Section */}
                    <div className="flex items-center gap-2">
                        <div className="hover:scale-110 transition-transform">
                            <HeartAndThumbIcon />
                        </div>
                        <p className="text-bodyThree font-medium text-gray-700">
                            {formatToK(totalLikeCount)}
                        </p>
                    </div>

                    {/* Info Section */}
                    <div className="flex items-center gap-4 text-sm text-gray-600">
                        <p className="font-medium">{source}</p>
                        <p className="font-medium">
                            {topicGroupId?.groupName || "No Group"}
                        </p>
                        <p className="font-medium">
                            {[
                                isFunny && "Funny",
                                isInsightful && "Insightful",
                                isShareworthy && "Shareworthy",
                            ]
                                .filter(Boolean)
                                .join(" | ")}
                        </p>
                    </div>
                </div>

                {/* Action Section */}
                <button
                    disabled={isPending}
                    className="w-full py-2 mt-4 text-sm font-bold text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
                    onClick={handleDelete}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};

export default MediaCard;
