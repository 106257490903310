import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import Intercom from '@intercom/messenger-js-sdk';
import { INTERCOM_KEY } from "../../utils/config";
import Banner from "./Banner";

const MainLayout = ({ children }) => {
    const authState = useSelector((state) => state.auth);
    Intercom({
        app_id: INTERCOM_KEY,
        user_id: authState._id,
        name: authState.name,
        email: authState.email,
        created_at: authState.createdAt,
    });
    return (
        <div className="min-h-screen p-2 bg-star-light4">
            {/* <Banner/> */}
            <div className="flex  gap-4">
                <div>
                    <Sidebar />
                </div>
                <div className="flex-1">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default MainLayout;