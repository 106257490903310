
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Xmark, NavArrowDown } from "iconoir-react";
import { useQuery } from "@tanstack/react-query";
import { fetchAllPlans } from "../services/payment.service";

const AddNewCoupon = ({ onSubmit, onClose }) => {
  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      isActive: true,
      plans: [],
      paymentMethod: "",
    },
  });

  const query = {
    plans: "coupon",
  };

  const availablePaymentMethod = ["card", "upi"];

  const type = ["monthly", "yearly",'both'];

  const { data: plans, isLoading: loadCoupons } = useQuery({
    queryKey: ["plans", query],
    queryFn: async () => {
      const response = await fetchAllPlans();
      return response;
    },
    staleTime: Infinity,
    retry: false,
  });

  const availablePlans = plans?.data?.plans || [];
  const watchPlans = watch("plans", []);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handlePlanChange = (planId) => {
    const updatedPlans = watchPlans.includes(planId)
      ? watchPlans.filter((id) => id !== planId)
      : [...watchPlans, planId];
    setValue("plans", updatedPlans);

    if (!planDetails[planId]) {
      setPlanDetails({
        ...planDetails,
        [planId]: { primaryText: "", secondaryText: "", amountAfterDiscount: "" },
      });
    }
  };

  const handleDetailChange = (planId, field, value) => {
    setPlanDetails({
      ...planDetails,
      [planId]: { ...planDetails[planId], [field]: value },
    });
  };

  const onSubmitForm = (data) => {
    const formattedPlans = watchPlans.map((planId) => ({
      planId,
      ...planDetails[planId],
    }));

    const finalData = {
      ...data,
      plans: formattedPlans,
    };

    console.log("Final Form Data:", finalData);
    onSubmit(finalData);
  };

  console.log("-----watchPlans----",watchPlans)

  const formFields = [
    { name: "name", label: "Coupon Name", type: "text", required: true },
    { name: "description", label: "Description", type: "text", required: true },
    { name: "discountPercentage", label: "Discount Percentage", type: "number", required: true, min: 0, max: 100 },
    { name: "usageLimit", label: "Usage Limit", type: "number", required: true, min: 1 },
    { name: "validFrom", label: "Valid From", type: "date", required: true },
    { name: "validTill", label: "Valid Till", type: "date", required: true },
  ];

  return (
    <div className="p-6 bg-gray-100 h-screen overflow-hidden">
      <div className="bg-white p-6 rounded-lg shadow-md max-w-6xl mx-auto h-full overflow-y-auto">
        <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-6">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-800">Add New Coupon</h2>
            <button
              className="icon-outline-btn small border-none"
              onClick={onClose}
            >
              <Xmark />
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Payment Method
              </label>
              <div className="flex space-x-4">
                {availablePaymentMethod.concat("both").map((method, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="radio"
                      id={`paymentMethod_${method}`}
                      value={method}
                      {...register("paymentMethod", {
                        required: "Payment method is required",
                      })}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                    />
                    <label
                      htmlFor={`paymentMethod_${method}`}
                      className="ml-2 text-sm font-medium text-gray-700"
                    >
                      {method.charAt(0).toUpperCase() + method.slice(1)}
                    </label>
                  </div>
                ))}
              </div>
              {errors.paymentMethod && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.paymentMethod.message}
                </p>
              )}
              {/* Dynamic Fields for Payment Method */}
              {watch("paymentMethod") === "upi" && (
                <div className="space-y-2 mt-4">
                  <label htmlFor="offerId_upi" className="block text-sm font-medium text-gray-700">
                    Offer ID for UPI
                  </label>
                  <input
                    id="offerId_upi"
                    type="text"
                    {...register("offerId_upi", {
                      required: "Offer ID for UPI is required",
                    })}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Enter Offer ID for UPI"
                  />
                  {errors.offerId_upi && (
                    <p className="text-red-500 text-sm mt-1">{errors.offerId_upi.message}</p>
                  )}
                </div>
              )}

              {watch("paymentMethod") === "card" && (
                <div className="space-y-2 mt-4">
                  <label htmlFor="offerId_card" className="block text-sm font-medium text-gray-700">
                    Offer ID for Card
                  </label>
                  <input
                    id="offerId_card"
                    type="text"
                    {...register("offerId_card", {
                      required: "Offer ID for Card is required",
                    })}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Enter Offer ID for Card"
                  />
                  {errors.offerId_card && (
                    <p className="text-red-500 text-sm mt-1">{errors.offerId_card.message}</p>
                  )}
                </div>
              )}

              {watch("paymentMethod") === "both" && (
                <div className="space-y-4 mt-4">
                  {/* UPI Field */}
                  <div className="space-y-2">
                    <label htmlFor="offerId_upi" className="block text-sm font-medium text-gray-700">
                      Offer ID for UPI
                    </label>
                    <input
                      id="offerId_upi"
                      type="text"
                      {...register("offerId_upi", {
                        required: "Offer ID for UPI is required when 'Both' is selected",
                      })}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Enter Offer ID for UPI"
                    />
                    {errors.offerId_upi && (
                      <p className="text-red-500 text-sm mt-1">{errors.offerId_upi.message}</p>
                    )}
                  </div>

                  {/* Card Field */}
                  <div className="space-y-2">
                    <label htmlFor="offerId_card" className="block text-sm font-medium text-gray-700">
                      Offer ID for Card
                    </label>
                    <input
                      id="offerId_card"
                      type="text"
                      {...register("offerId_card", {
                        required: "Offer ID for Card is required when 'Both' is selected",
                      })}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Enter Offer ID for Card"
                    />
                    {errors.offerId_card && (
                      <p className="text-red-500 text-sm mt-1">{errors.offerId_card.message}</p>
                    )}
                  </div>
                </div>
              )}
            </div>


            {/* <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Type</label>
                <div className="flex space-x-4">
                  {type.map((method, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="radio"
                        id={`type_${method}`}
                        value={method}
                        {...register("type", { required: "type is required" })}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                      />
                      <label htmlFor={`type_${method}`} className="ml-2 text-sm font-medium text-gray-700">
                        {method}
                      </label>
                    </div>
                  ))}
                </div>
              </div> */}

            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="isActive"
                {...register("isActive")}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="isActive" className="text-sm font-medium text-gray-700">Active</label>
            </div>
            {formFields.map(({ name, label, type, required, min, max }) => (
              <div key={name} className="space-y-2">
                <label
                  htmlFor={name}
                  className="block text-sm font-medium text-gray-700"
                >
                  {label}
                </label>
                {type === "textarea" ? (
                  <textarea
                    id={name}
                    {...register(name, {
                      required: required ? `${label} is required` : false,
                    })}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    rows="3"
                    placeholder={`Enter ${label.toLowerCase()}`}
                  ></textarea>
                ) : (
                  <input
                    id={name}
                    type={type}
                    {...register(name, {
                      required: required ? `${label} is required` : false,
                      ...(min !== undefined && {
                        min: {
                          value: min,
                          message: `${label} cannot be less than ${min}`,
                        },
                      }),
                      ...(max !== undefined && {
                        max: {
                          value: max,
                          message: `${label} cannot exceed ${max}`,
                        },
                      }),
                    })}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder={`Enter ${label.toLowerCase()}`}
                  />
                )}
                {errors[name] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors[name].message}
                  </p>
                )}
              </div>
            ))}
          </div>

          {/* Plan Selection */}
          <div className="space-y-2">
            <label htmlFor="plans" className="block text-sm font-medium text-gray-700">
              Plans
            </label>
            <div className="relative">
              <button
                type="button"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 flex justify-between items-center"
              >
                {watchPlans.length > 0
                  ? `Selected (${watchPlans.length})`
                  : "Select Plans"}
                <NavArrowDown
                  className={`h-5 w-5 transform transition ${dropdownOpen ? "rotate-180" : "rotate-0"
                    }`}
                />
              </button>
              {dropdownOpen && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10 max-h-40 overflow-y-auto">
                  {availablePlans.map((plan) => (
                    <div
                      key={plan?._id}
                      className="flex items-center px-3 py-2 hover:bg-gray-100"
                    >
                      <input
                        type="checkbox"
                        id={`plan_${plan?._id}`}
                        checked={watchPlans.includes(plan?._id)}
                        onChange={() => handlePlanChange(plan?._id)}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label
                        htmlFor={`plan_${plan?.name}`}
                        className="ml-2 text-sm font-medium text-gray-700"
                      >
                        {plan?.name} ({plan?.currency}) ({plan?.billingPeriod})
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Dynamic Fields for Selected Plans */}
          {watchPlans.map((planId) => (
            <div key={planId} className="space-y-4 bg-gray-50 p-4 rounded-md">
              <h3 className="text-lg font-medium text-gray-700">
                Details for Plan ID: {planId}
              </h3>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Primary Text
                </label>
                <input
                  type="text"
                  value={planDetails[planId]?.primaryText || ""}
                  onChange={(e) =>
                    handleDetailChange(planId, "primaryText", e.target.value)
                  }
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Enter primary text"
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Secondary Text
                </label>
                <input
                  type="text"
                  value={planDetails[planId]?.secondaryText || ""}
                  onChange={(e) =>
                    handleDetailChange(planId, "secondaryText", e.target.value)
                  }
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Enter secondary text"
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Amount After Discount
                </label>
                <input
                  type="number"
                  value={planDetails[planId]?.amountAfterDiscount || ""}
                  onChange={(e) =>
                    handleDetailChange(planId, "amountAfterDiscount", e.target.value)
                  }
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Enter amount after discount"
                  required
                />
              </div>
            </div>
          ))}

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Coupon
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddNewCoupon;
