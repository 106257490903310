import { useMutation, useQueryClient, } from "@tanstack/react-query";
import { Xmark } from "iconoir-react";
import { useState } from "react";
import { toast } from "react-toastify";
import DialogBox from "../../component/DialogBox";
import InputField from "../../component/custom-form/InputField";
import { editTrendingTopic } from "../../services/trending.service";
import ChipInput from "../../component/custom-form/ChipInput";
import moment from "moment";


const EditTrendingTopic = ({ data, onClose }) => {
    const queryClient = useQueryClient();

    console.log(data)
    const [formData, setFormData] = useState({
        topicGroupId: data?._id || null,
        displayName: data?.groupName || '',
        topics: data?.topics?.map((item) => item.topic),
        startDate: data?.startDate ? moment(data.startDate).format('YYYY-MM-DD') : '', // Correct format for <input type="date" />
        region: data?.region,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const { mutate, isPending } = useMutation({
        mutationFn: editTrendingTopic,
        onSuccess: () => {
            toast.success("Topic added successfully!");
            queryClient.invalidateQueries({ queryKey: ['trending-topic'] })
            onClose();
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong');
        },
    });

    const handleTopicChange = (value) => {
        setFormData((prev) => {
            return {
                ...prev,
                topics: value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        mutate(formData)
    };
    return (
        <DialogBox >
            <div className="bg-white px-6 py-6 rounded-md w-[500px]">
                <div className="flex justify-between mb-6">

                    <h1 className="text-2xl font-bold ">Add Trending Topic</h1>
                    <div className="icon-outline-btn border-none small cursor-pointer" onClick={onClose}>
                        <Xmark />
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    {/* Topic Name Input */}
                    <div>
                        <label htmlFor="displayName" className="block text-sm mb-1 font-medium text-gray-700">
                            Display Name
                        </label>
                        <InputField
                            name='displayName'
                            value={formData.displayName}
                            onChange={handleInputChange}
                            placeholder="Enter display name"
                            required
                        />

                    </div>

                    <div className="mt-6">
                        <label htmlFor="topics" className="block text-sm mb-1 font-medium text-gray-700">
                            Topics
                        </label>
                        <ChipInput chips={formData.topics} setChips={handleTopicChange} />
                    </div>
                    {/* Region Select */}
                    <div className="mt-6">
                        <label htmlFor="region" className="block text-sm mb-1 font-medium text-gray-700">
                            Region
                        </label>
                        <select
                            id="region"
                            name="region"
                            value={formData.region}
                            onChange={handleInputChange}
                            className="rounded-lg text-bodyTwo text-space-default bg-gray-moon  py-2.5 px-3.5 w-full"
                            style={{
                                border: "1px solid #E0DDED"
                            }}
                            required
                        >
                            <option value="india">India</option>
                            <option value="outside_india">Outside India</option>
                        </select>
                    </div>

                    {/* Start Date Input */}
                    <div className="mt-6">
                        <label htmlFor="startDate" className="block text-sm mb-1 font-medium text-gray-700">
                            Start Date
                        </label>
                        <input
                            type="date"
                            id="startDate"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleInputChange}
                            className="rounded-lg text-bodyTwo text-space-default bg-gray-moon py-2.5 px-3.5 w-full"
                            style={{
                                border: "1px solid #E0DDED"
                            }}
                            required
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="mt-8">
                        <button
                            type="submit"
                            className="filled-btn w-full small"
                            disabled={isPending}
                        >
                            Add Topic
                        </button>
                    </div>
                </form>
            </div>
        </DialogBox>
    )
}
export default EditTrendingTopic;