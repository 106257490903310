import { useQuery } from "@tanstack/react-query";
import MainLayout from "../../layout/main";
import { fetchServerInfo, restartServer } from "../../services/admin.service";
import moment from "moment";
import { toast } from "react-toastify";

const ServerInfo = () => {
    const { data: serverInfo, isLoading, refetch } = useQuery({
        queryKey: ["server-info"],
        queryFn: fetchServerInfo, // Directly pass the function
    });

    const handleRestartClick = async (endpoint) => {
        try {
            await restartServer(endpoint);
            toast.success("Server started successfully")

        } catch (error) {
            console.log(error.message)
            toast.error("Some error occur", error)
        }
    }

    return (
        <MainLayout>
            <div className="rounded-2xl mt-2 pt-28 px-4 relative bg-star-light3 min-h-screen">
                {
                    !isLoading ?

                        <div>
                            <h1 className="text-2xl font-bold mb-4">Server Information</h1>
                            <table className="min-w-full border-collapse border border-gray-300">
                                <thead>
                                    <tr className="bg-gray-100">
                                        <th className="border border-gray-300 px-4 py-2 text-left">Server Name</th>
                                        <th className="border border-gray-300 px-4 py-2 text-left">Endpoint</th>
                                        <th className="border border-gray-300 px-4 py-2 text-left">Status</th>
                                        <th className="border border-gray-300 px-4 py-2 text-left">Updated At</th>
                                        <th className="border border-gray-300 px-4 py-2 text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {serverInfo?.map((server) => (
                                        <tr key={server._id} className="bg-star-light4">
                                            <td className="border border-gray-300 px-4 py-2">{server.serverName}</td>
                                            <td className="border border-gray-300 px-4 py-2">{server.endpoint}</td>
                                            <td className="border border-gray-300 px-4 py-2">
                                                {server.status ? "Active" : "Inactive"}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2">
                                                {moment(server.updatedAt).format("MMMM Do, h:mm")}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2">
                                                {
                                                    !server.status ?
                                                        <button className="filled-btn small" onClick={() => { handleRestartClick(server.endpoint) }}>Restart</button>
                                                        : '--'
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        :
                        <p className="text-center">Loading...</p>
                }

            </div>
        </MainLayout>
    );
};

export default ServerInfo;
