import { MoreVert } from "iconoir-react";
import { useState, useEffect, useRef } from "react";
import { startLinkedinTopicScraper, startTwitterTopicScraper, toggleGroupStatus, toggleHotTopic } from "../../services/trending.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const ActionPopup = ({ groupData, onEdit, onDelete }) => {
    const queryClient = useQueryClient();
    const [openPopup, setOpenPopup] = useState(false);

    const popupRef = useRef(null);

    const handleOpen = (event) => {
        event.stopPropagation();
        setOpenPopup((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setOpenPopup(false);
        }
    };

    useEffect(() => {
        if (openPopup) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [openPopup]);

    const { mutate } = useMutation({
        mutationFn: startLinkedinTopicScraper,
        onSuccess: () => {
            toast.success("Linkedin scraper start successfully");
        },
        onError: (error) => {
            toast.error(error?.message || "Something went wrong");
        },
    });
    const { mutate: twitterScraperMutate } = useMutation({
        mutationFn: startTwitterTopicScraper,
        onSuccess: () => {
            toast.success("Twitter scraper start successfully");
        },
        onError: (error) => {
            toast.error(error?.message || "Something went wrong");
        },
    });

    const { mutate: toggleGroupStatusMutate } = useMutation({
        mutationFn: toggleGroupStatus,
        onSuccess: () => {
            toast.success("Group Status changed successfully!");
            queryClient.invalidateQueries({ queryKey: ['trending-topic'] })

        },
        onError: (error) => {
            toast.error(error?.message || "Something went wrong");
        },
    });
    const { mutate: toggleHotTopicsMutate } = useMutation({
        mutationFn: toggleHotTopic,
        onSuccess: () => {
            toast.success("Group Status changed successfully!");
            queryClient.invalidateQueries({ queryKey: ['trending-topic'] })

        },
        onError: (error) => {
            toast.error(error?.message || "Something went wrong");
        },
    });

    // console.log(groupData)
    return (
        <>
            <div className="absolute top-0" ref={popupRef}>
                <button
                    className="icon-outline-btn border-none small"
                    onClick={handleOpen}
                    aria-expanded={openPopup}
                    aria-haspopup="menu"
                >
                    <MoreVert />
                </button>
                {openPopup && (
                    <div
                        className="absolute top-full right-0 mt-2 bg-white shadow-lg rounded-lg p-2 z-50"
                        role="menu"
                        aria-label="Action Menu"
                    >
                        <button
                            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                            onClick={(event) => {
                                event.stopPropagation();
                                onEdit?.();
                                setOpenPopup(false);
                            }}
                        >
                            Edit
                        </button>
                        <button
                            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                            onClick={(event) => {
                                event.stopPropagation();
                                mutate(groupData._id);
                                setOpenPopup(false);
                            }}
                        >
                            Start Linkedin scraper
                        </button>
                        <button
                            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                            onClick={(event) => {
                                event.stopPropagation();
                                twitterScraperMutate(groupData._id);
                                setOpenPopup(false);
                            }}
                        >
                            Start Twitter scraper
                        </button>
                        <button
                            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 "
                            onClick={(event) => {
                                event.stopPropagation();
                                toggleGroupStatusMutate(groupData._id);
                                setOpenPopup(false);
                            }}
                        >
                            {groupData.isActive ? 'Deactivate' : 'Activate'}
                        </button>
                        <button
                            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 "
                            onClick={(event) => {
                                event.stopPropagation();
                                toggleHotTopicsMutate(groupData._id);
                                setOpenPopup(false);
                            }}
                        >
                            Toggle Hot Topic
                        </button>
                        <button
                            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 text-red-600"
                            onClick={(event) => {
                                event.stopPropagation();
                                onDelete?.();
                                setOpenPopup(false);
                            }}
                        >
                            Delete
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default ActionPopup;
