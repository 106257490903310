import { useQuery } from "@tanstack/react-query";
import MasonryLayout from "../../component/MasonaryLayout";
import MediaCard from "../../component/MediaCard";
import MainLayout from "../../layout/main";
import { fetchTrendingGroup, fetchTrendingMedia } from "../../services/trending.service";
import { Plus } from "iconoir-react";
import { useState } from "react";
import SelectBox from "../../component/custom-form/SelectBox";
import AddMedia from "./addMedia";

const contentToneOption = [
    {
        label: 'Funny',
        value: 'funny',
    },
    {
        label: 'Insightful',
        value: 'insightful',

    },
    {
        label: 'Relatable',
        value: 'shareworthy',
    },
]

const contentSourceOption = [
    {
        label: 'Linkedin',
        value: 'linkedin',
    },
    {
        label: 'Twitter',
        value: 'twitter',

    },
    {
        label: 'Manual',
        value: 'manual',
    },
]

const TrendingMedia = () => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [topicGroupFilter, setTopicGroupFilter] = useState({});
    const [contentToneFilter, setContentToneFilter] = useState({});
    const [contentSourceFilter, setContentSourceFilter] = useState({});

    const query = {
        limit: 100,
        topicGroupId: topicGroupFilter.value,
        isFunny: contentToneFilter.value === 'funny' ? true : undefined,
        isInsightful: contentToneFilter.value === 'insightful' ? true : undefined,
        isShareworthy: contentToneFilter.value === 'shareworthy' ? true : undefined,
        source: contentSourceFilter.value
    }
    const trendingGroupData = useQuery({
        queryKey: ['trending-group', query],
        queryFn: async ({ queryKey }) => fetchTrendingGroup(queryKey[1]),
        staleTime: Infinity,
        retry: false,
    });

    let topicGroupOption = trendingGroupData?.data?.data?.map((item) => {
        return {
            label: `${item.groupName} | ${item.region}`,
            value: item._id
        }
    }) || [];

    const { data } = useQuery({
        queryKey: ['trending-media', query],
        queryFn: async ({ queryKey }) => fetchTrendingMedia(queryKey[1]),
        staleTime: Infinity,
        retry: false,
    });

    return (
        <MainLayout>
            {openAddDialog && <AddMedia topicGroupOption={topicGroupOption} onClose={() => { setOpenAddDialog(false) }} />}
            <div className="container rounded-xl min-h-[97vh] bg-star-light3 mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6 text-gray-800">Trending Media</h1>
                <div className="flex justify-between mb-4 items-center">
                    <div className="flex items-center gap-4">
                        <SelectBox
                            value={topicGroupFilter}
                            label="Topic Group"
                            options={topicGroupOption}
                            onRemoveFilter={() => setTopicGroupFilter({})}
                            onChange={setTopicGroupFilter}
                        />
                        <SelectBox
                            value={contentToneFilter}
                            // icon={<MicstageIcon />}
                            label="Content Tone"
                            options={contentToneOption}
                            onRemoveFilter={() => { setContentToneFilter({}) }}
                            onChange={setContentToneFilter}
                        />
                        <SelectBox
                            value={contentSourceFilter}
                            // icon={<MicstageIcon />}
                            label="Content Source"
                            options={contentSourceOption}
                            onRemoveFilter={() => { setContentSourceFilter({}) }}
                            onChange={setContentSourceFilter}
                        />
                    </div>
                    <button className="filled-btn small" onClick={() => { setOpenAddDialog(true) }}> <Plus />Add new media</button>
                </div>
                <div>
                    <MasonryLayout>
                        {
                            data?.data?.map((postItem) => (
                                <MediaCard {...postItem} />
                            ))
                        }
                    </MasonryLayout>
                </div>
            </div>
        </MainLayout>
    )
}
export default TrendingMedia;