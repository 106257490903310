import api from "../utils/api";
import axios from "axios";
import { LINKEDIN_TOPIC_SCRAPPER_BASE_URL, TWITTER_TOPIC_SCRAPPER_BASE_URL } from "../utils/config";

export const fetchTrendingTopic = async (data) => {
    try {
        const response = await api.get('/admin/topic', {
            params: data
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const addTrendingTopic = async (data) => {
    try {
        const response = await api.post('/admin/topic/create', data
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const deleteTrendingTopicGroup = async (topicId) => {
    try {
        const response = await api.delete(`/admin/topicbygroup/delete/${topicId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const editTrendingTopic = async (data) => {
    try {
        const response = await api.put('/admin/topic/edit', data
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const fetchTrendingMedia = async (data) => {
    try {
        const response = await api.get('/admin/trending-media', {
            params: data
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};


export const fetchTrendingGroup = async (data) => {
    try {
        const response = await api.get('/admin/topic-group', {
            params: data
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const addMedia = async (data) => {
    try {
        const response = await api.post('/admin/add-media', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
export const deleteMedia = async (mediaId) => {
    try {
        const response = await api.delete(`/admin/delete-media/${mediaId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const startLinkedinTopicScraper = async (groupId) => {
    try {
        // const linkedinScraperBaseURL = 'http://localhost:8081/v1/scraper/start-keyword-scraper'

        const linkedinScraperBaseURL = `${LINKEDIN_TOPIC_SCRAPPER_BASE_URL}/scraper/start-keyword-scraper`
        const response = await axios.post(linkedinScraperBaseURL, {
            groupId
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
export const startTwitterTopicScraper = async (groupId) => {
    try {
        // const linkedinScraperBaseURL = 'http://localhost:8081/v1/scraper/start-keyword-scraper'

        const linkedinScraperBaseURL = `${TWITTER_TOPIC_SCRAPPER_BASE_URL}/scraper/start-keyword-scraper`
        const response = await axios.post(linkedinScraperBaseURL, {
            groupId
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const toggleGroupStatus = async (groupId) => {
    try {
        const response = await api.post(`/admin/toggle-group-status/${groupId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
export const toggleHotTopic = async (groupId) => {
    try {
        const response = await api.post(`/admin/toggle-hot-topic/${groupId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};