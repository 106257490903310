import axios from "axios";
import api from "../utils/api";

export const fetchPostDetails = async (data) => {
    try {
        const response = await api.post('/admin/posts', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const fetchProfileStats = async (data) => {
    try {
        const response = await api.post('/admin/profile/stats', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const fetchServerInfo = async (data) => {
    try {
        const response = await api.get('/admin/server/fetch-info');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};


export const restartServer = async (endpoint) => {
    console.log(`http://${endpoint}:8081/v1/scraper/start`);
    try {
        const response = await axios.get(`http://${endpoint}:8081/v1/scraper/start`);
        return response.data
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const fetchCoupon = async (data) => {
    try {
        const response = await api.get('/coupons', {
            params: data
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const addCoupon = async (data) => {
    try {
        const response = await api.post('/coupons/add-coupons',data
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const deleteCoupon = async (data) => {
    try {
        const response = await api.post(`/coupons/delete-coupons/${data}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
