import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { saveTemplates, saveTemplatesCategory } from '../../services/template.service';

function CategoryFormModal({ isOpen, onClose }) {
    const [category, setCategory] = useState('');

    const handleTemplateCreation = () => {
        toast.success('Category Added');
        setCategory('')
        onClose();
    };

    const { mutate, isPending } = useMutation({
        mutationFn: saveTemplatesCategory,
        onSuccess: () => handleTemplateCreation(),
        onError: () => {
            toast.error('Something went wrong');
        },
    });

    const onSubmit = (e) => {
        e.preventDefault();

        // Validate the form
        if (!category) {
            toast.error('Please fill in all fields.');
            return;
        }

        mutate({ category });
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded shadow-lg p-6 w-full max-w-[40%] max-h-screen overflow-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Create New Category</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 font-semibold">✕</button>
                </div>
                <form onSubmit={onSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Category Name
                        </label>
                        <input
                            id="name"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                            rows="8"
                            required
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2 hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            disabled={isPending} // Disable the button while mutation is in progress
                        >
                            {isPending ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CategoryFormModal;
