import MainLayout from "../../layout/main";
import { useMutation } from "@tanstack/react-query";
import { fetchProfileStats } from "../../services/admin.service";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import InputField from "../../component/form/InputField";
import moment from "moment";

const UserProfileStats = ({ source }) => {
    const [profileStats, setProfileStats] = useState({
        lastScraped: null,
        lastSynced: null,
    });

    // Validation schema using Zod
    const usernameSchema = z.object({
        username: z
            .string()
            .min(4, "username must be at least 4 characters")
        //   .max(25, "Username should not exceed 25 characters"),
    });

    const {
        handleSubmit,
        control,
        setError,
        reset,
    } = useForm({
        resolver: zodResolver(usernameSchema),
        defaultValues: { username: "" },
    });

    const { mutate, isLoading } = useMutation({
        mutationFn: fetchProfileStats,
        onSuccess: (data) => {
            setProfileStats({
                lastScraped: data.lastScraped || "--",
                lastSynced: data.lastSynced || "--",
            });
            toast.success("Profile stats fetched successfully!");
            reset(); // Reset the form after success
        },
        onError: () => {
            setError("username", { type: "validate", message: "Invalid username" });
            toast.error("Something went wrong. Please try again.");
        },
    });

    const onSubmit = (formData) => {
        mutate({ username: formData.username, source });
    };

    const StatisticCard = ({ label, value, icon, bgColor, textColor }) => {
        return (
            <div
                className={`${bgColor} ${textColor} p-5 rounded-lg shadow-sm flex items-center justify-between border border-gray-200`}
            >
                <div className="flex flex-col">
                    <p className="text-sm font-semibold">{label}</p>
                    <p className="text-2xl font-bold">{value}</p>
                </div>
                <div className="text-3xl">{icon}</div>
            </div>
        );
    };

    return (
        <div className="rounded-2xl  px-6 py-8 relative bg-star-light3 min-h-screen">
            {/* Input Section */}
            <p className="text-lg font-medium text-gray-700 mb-4">
                Enter a username to fetch profile stats:
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full sm:w-[60%] flex items-center gap-4">
                <InputField
                    name="username"
                    type="text"
                    control={control}
                    placeholder="Enter username"
                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-star-default focus:border-transparent"
                />
                <button
                    className={`filled-btn small text-base px-6 py-2 rounded-lg ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                        } bg-star-default text-white`}
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? "Loading..." : "Search"}
                </button>
            </form>

            <div className="w-full bg-gradient-to-br from-white to-gray-100 p-6 rounded-2xl border border-gray-300 shadow-sm mt-8">
                <p className="text-xl font-semibold text-gray-700 mb-6">Profile Stats</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <StatisticCard
                        label="Last Scraped"
                        value={
                            profileStats.lastScraped
                                ? moment(profileStats.lastScraped).format("MMMM Do YYYY")
                                : "--"
                        }
                        icon="📥"
                        bgColor="bg-blue-100"
                        textColor="text-blue-600"
                    />
                    <StatisticCard
                        label="Last Synced"
                        value={
                            profileStats.lastSynced
                                ? moment(profileStats.lastSynced).format("MMMM Do YYYY")
                                : "--"
                        }
                        icon="🔄"
                        bgColor="bg-green-100"
                        textColor="text-green-600"
                    />
                </div>
            </div>

        </div>

    );
};

export default UserProfileStats;
