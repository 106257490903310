import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Xmark } from "iconoir-react";
import { useState } from "react";
import { toast } from "react-toastify";
import DialogBox from "../../component/DialogBox";
import { addMedia } from "../../services/trending.service";

const AddMedia = ({ onClose, topicGroupOption }) => {
    const queryClient = useQueryClient();
    const [topicGroupFilter, setTopicGroupFilter] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [selectedTones, setSelectedTones] = useState([]);

    const tones = ["funny", "shareworthy", "insightful"];

    const { mutate, isLoading: isPending } = useMutation({
        mutationFn: addMedia,
        onSuccess: () => {
            toast.success("Topic added successfully!");
            queryClient.invalidateQueries({ queryKey: ["trending-media"] });
            onClose();
        },
        onError: (error) => {
            toast.error(error?.message || "Something went wrong");
        },
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith("image/")) {
            setImageFile(file);
        } else {
            toast.error("Please select a valid image file.");
            setImageFile(null);
        }
    };

    const handleToneChange = (tone) => {
        setSelectedTones((prevTones) =>
            prevTones.includes(tone)
                ? prevTones.filter((t) => t !== tone)
                : [...prevTones, tone]
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!imageFile) {
            toast.error("Please upload an image.");
            return;
        }
        if (!topicGroupFilter) {
            toast.error("Please select a topic group.");
            return;
        }
        if (selectedTones.length === 0) {
            toast.error("Please select at least one tone.");
            return;
        }

        const formData = new FormData();
        formData.append("topicGroupId", topicGroupFilter);
        formData.append("image", imageFile);
        formData.append("funny", selectedTones.includes('funny')); // Send tones as JSON string
        formData.append("shareworthy", selectedTones.includes('shareworthy')); // Send tones as JSON string
        formData.append("insightful", selectedTones.includes('insightful')); // Send tones as JSON string

        mutate(formData);
    };

    return (
        <DialogBox>
            <div className="bg-white px-6 py-6 rounded-md w-[500px]">
                <div className="flex justify-between mb-6">
                    <h1 className="text-2xl font-bold">Add Trending Topic</h1>
                    <div
                        className="icon-outline-btn border-none small cursor-pointer"
                        onClick={onClose}
                    >
                        <Xmark />
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    {/* Group Select */}
                    <div className="mt-6">
                        <label
                            htmlFor="topicGroup"
                            className="block text-sm mb-1 font-medium text-gray-700"
                        >
                            Group
                        </label>
                        <select
                            id="topicGroup"
                            value={topicGroupFilter}
                            onChange={(e) => setTopicGroupFilter(e.target.value)}
                            className="rounded-lg text-bodyTwo text-gray-800 bg-gray-100 py-2.5 px-3.5 w-full border border-gray-300"
                            required
                        >
                            <option value="" disabled>
                                Select a topic group
                            </option>
                            {topicGroupOption.map((group) => (
                                <option key={group.value} value={group.value}>
                                    {group.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Tone Multi-Select */}
                    <div className="mt-6">
                        <label
                            htmlFor="tones"
                            className="block text-sm mb-1 font-medium text-gray-700"
                        >
                            Tones
                        </label>
                        <div className="flex gap-2 flex-wrap">
                            {tones.map((tone) => (
                                <label
                                    key={tone}
                                    className="flex items-center space-x-2"
                                >
                                    <input
                                        type="checkbox"
                                        value={tone}
                                        checked={selectedTones.includes(tone)}
                                        onChange={() => handleToneChange(tone)}
                                        className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                    />
                                    <span>{tone}</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Image Upload */}
                    <div className="mt-6">
                        <label
                            htmlFor="media"
                            className="block text-sm mb-1 font-medium text-gray-700"
                        >
                            Media
                        </label>
                        <input
                            id="media"
                            type="file"
                            accept="image/*"
                            required
                            onChange={handleImageChange}
                            className="rounded-lg text-bodyTwo text-gray-800 bg-gray-100 py-2.5 px-3.5 w-full border border-gray-300"
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="mt-8">
                        <button
                            type="submit"
                            className="filled-btn w-full small"
                            disabled={isPending}
                        >
                            {isPending ? "Adding..." : "Add Media"}
                        </button>
                    </div>
                </form>
            </div>
        </DialogBox>
    );
};

export default AddMedia;
