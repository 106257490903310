import React, { useEffect, useState } from 'react';
import TemplateList from './templateList';
import NewTemplateModal from './templateForm';
import MainLayout from '../../layout/main';
import { useQuery } from '@tanstack/react-query';
import { fetchTemplateCategory, fetchTemplates } from '../../services/template.service';
import CategoryFormModal from './categoryForm';

function TemplatePage() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [templateData, setTemplateData] = useState(null);
    const [postCount, setPostCount] = useState(0);

    const [selectedTemplateType, setSelectedTemplateType] = useState({ TemplateId: '' }); // New state for the selected type

    const addTemplate = () => {
        setIsModalOpen(false); // Close the modal
        setTemplateData(null);
    };

    const addTemplateCategory = () => {
        setIsCategoryModalOpen(false); // Close the modal
    };

    // const filteredTemplates = templates.filter(template =>
    //     template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     template.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     template.body.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     template.example.toLowerCase().includes(searchTerm.toLowerCase())
    // )

    const { data: categories = [], isLoading } = useQuery({
        queryKey: ['template-type'],
        queryFn: () => fetchTemplateCategory(),
    });

    const handleTemplateTypeChange = (e) => {
        const selectedId = e.target.value;
        setSelectedTemplateType({ TemplateId: selectedId }); // Ensure state is updated correctly
    };

    const handleEditTemplate = (templateData) => {
        setTemplateData(templateData);
        setIsModalOpen(true)
    };

    return (
        <MainLayout>
            <div className="container p-4">
                <div className="flex justify-between items-center mb-4">
                    {/* Search Bar */}
                    {/* <div className="relative w-full max-w-md">
                        <input
                            type="text"
                            placeholder="Search templates..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
                            style={{ paddingLeft: '2.5rem' }}
                        />
                        <span
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                            style={{ pointerEvents: 'none' }}
                        >
                            🔍
                        </span>
                    </div> */}

                    <div>
                        <button onClick={() => setIsCategoryModalOpen(true)} className="filled-btn small text-white py-2 px-4 rounded flex items-center mr-3">
                            Add Category
                        </button>
                    </div>

                    <p className='text-bodyOne'>Number of Templates: {postCount}</p>
                    <div className='flex'>


                        {/* Template Type Dropdown */}
                        <div className="relative w-full max-w-md mr-4">
                            <select
                                value={selectedTemplateType}
                                onChange={handleTemplateTypeChange}
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
                            >
                                <option value="">Select Template Type</option>
                                {categories.templateType?.map((category) => (
                                    <option value={category._id}>
                                        {category.templateType}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Create New Template Button */}
                        <button onClick={() => setIsModalOpen(true)} className="filled-btn small text-white py-2 px-4 rounded flex items-center mr-3">
                            <svg className="mr-2 h-4 w-4" fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                            </svg>
                            Create New Template
                        </button>
                    </div>
                </div>

                {/* Template List */}
                <TemplateList onEditTemplate={handleEditTemplate} searchTerm={searchTerm} selectedTemplateType={selectedTemplateType} onPostCount={setPostCount} />

                {/* New Template Modal */}
                <NewTemplateModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        setTemplateData(null);  
                    }}
                    onSubmit={addTemplate}
                    templateType={categories.templateType}
                    templateData={templateData}
                />

                <CategoryFormModal
                    isOpen={isCategoryModalOpen}
                    onClose={() => setIsCategoryModalOpen(false)}
                    onSubmit={addTemplateCategory}
                />
            </div>
        </MainLayout>
    );
}

export default TemplatePage;