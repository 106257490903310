import React from "react";
import { Xmark } from 'iconoir-react'
const ChipInput = ({ chips, setChips }) => {
    const [inputValue, setInputValue] = React.useState("");

    // Add chip when pressing Enter or comma
    const handleKeyDown = (e) => {
        if ((e.key === "Enter" || e.key === ",") && inputValue.trim() !== "") {
            e.preventDefault();
            addChip(inputValue.trim());
        } else if (e.key === "Backspace" && inputValue === "" && chips.length > 0) {
            removeLastChip();
        }
    };

    // Add chip function
    const addChip = (value) => {
        if (!chips.includes(value)) {
            setChips([...chips, value]);
        }
        setInputValue("");
    };
    
    const removeLastChip = () => {
        const updatedChips = [...chips];
        updatedChips.pop();
        setChips(updatedChips);
    };
    // Remove chip function
    const removeChip = (chipToRemove) => {
        setChips(chips.filter((chip) => chip !== chipToRemove));
    };

    // Handle input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value?.toLowerCase() || "");
    };

    return (
        <div className="rounded-lg inline-flex flex-wrap gap-2 border text-bodyTwo text-space-default bg-gray-moon p-0.5  w-full">
            {chips.map((chip, index) => (
                <div
                    key={index}
                    className=" flex items-center px-3 py-1 bg-white border border-gray-3 rounded-full"
                >
                    {chip}
                    <button
                        type="button"
                        className="remove-chip-btn ml-2 rounded-full w-[24px] h-[24px] flex items-center justify-center hover:bg-mars-light text-mars-default"
                        onClick={() => removeChip(chip)}
                    >
                        <Xmark width={18} />
                    </button>
                </div>
            ))}
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Add a value and press Enter or comma"
                className="px-3 bg-transparent outline-none border-none py-2 border rounded flex-grow"
            />
        </div>
    );
};

export default ChipInput;