import React, { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { saveTemplates } from '../../services/template.service';

function NewTemplateModal({ isOpen, onClose, templateType, templateData }) {
    const [category, setCategory] = useState('');
    const [body, setBody] = useState('');
    const [name, setName] = useState('');
    const [example, setExample] = useState('');

    // Populate the form when `templateData` changes (Edit Mode)
    useEffect(() => {
        if (templateData) {
            setCategory(templateData.templateid || '');
            setBody(templateData.body || '');
            setName(templateData.name || '');
            setExample(templateData.example || '');
        }
    }, [templateData]);

    const handleTemplateSave = () => {
        toast.success(templateData ? 'Template Updated' : 'Template Created');
        handleOnClose()
    };

    const { mutate, isPending } = useMutation({
        mutationFn: saveTemplates,
        onSuccess: () => handleTemplateSave(),
        onError: () => {
            toast.error('Something went wrong');
            handleOnClose()
        },
    });

    const handleOnClose = () => {
        setCategory('');
        setBody('');
        setName('');
        setExample('');
        onClose();
    }

    const onSubmit = (e) => {
        e.preventDefault();

        // Validate the form
        if (!category || !name || !body || !example) {
            toast.error('Please fill in all fields.');
            return;
        }

        const payload = {
            name,
            templateId: category,
            content: body,
            contentExample: example,
        };

        if (templateData) {
            payload.id = templateData.id;
        }

        mutate(payload);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded shadow-lg p-6 w-full max-w-[70%] max-h-screen overflow-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">
                        {templateData ? 'Edit Template' : 'Create New Template'}
                    </h2>
                    <button
                        onClick={handleOnClose}
                        className="text-gray-500 hover:text-gray-700 font-semibold"
                    >
                        ✕
                    </button>
                </div>
                <form onSubmit={onSubmit} className="space-y-4">
                    <div>
                        <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Category
                        </label>
                        <select
                            id="category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                            required
                        >
                            <option value="">Select Template Type</option>
                            {templateType.map((type) => (
                                <option key={type._id} value={type._id}>
                                    {type.templateName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Name
                        </label>
                        <input
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                            required
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="body"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Body Content
                        </label>
                        <textarea
                            id="body"
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                            rows="8"
                            required
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="example"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Example Content
                        </label>
                        <textarea
                            id="example"
                            value={example}
                            onChange={(e) => setExample(e.target.value)}
                            rows="8"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                            required
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={handleOnClose}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2 hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            disabled={isPending}
                        >
                            {isPending
                                ? templateData
                                    ? 'Updating...'
                                    : 'Creating...'
                                : templateData
                                    ? 'Update Template'
                                    : 'Create Template'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewTemplateModal;
