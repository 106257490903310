import MainLayout from "../../layout/main";
import { useQuery } from "@tanstack/react-query";
import { fetchPostDetails } from "../../services/admin.service";
import { useState, useCallback } from "react";
import moment from "moment";
import UserProfileStats from "./userProfileStats";

const AdminPage = () => {
    const [source, setSource] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleChange = useCallback((event) => {
        setSource(Number(event.target.value));
    }, []);

    const handleStartDateChange = useCallback((event) => {
        setStartDate(event.target.value);
    }, []);

    const handleEndDateChange = useCallback((event) => {
        setEndDate(event.target.value);
    }, []);

    const { data: adminStats, isLoading, refetch } = useQuery({
        queryKey: ["postStats", { source, startDate, endDate }],
        queryFn: ({ queryKey }) => fetchPostDetails(queryKey[1]),
        enabled: !!source, // Ensures the query runs only when source is valid
    });

    const StatisticRow = ({ label, value }) => (
        <div className="flex flex-1 justify-between mb-2 border py-2">
            <div className="w-[50%] text-center">
                <p>{label}</p>
            </div>
            <div className="w-[50%] text-center">
                <p>{value ?? "--"}</p>
            </div>
        </div>
    );

    const StatisticCard = ({ label, value, icon, bgColor, textColor }) => {
        return (
            <div
                className={`${bgColor} ${textColor} p-4 rounded-lg shadow-sm flex items-center justify-between border border-gray-200`}
            >
                <div className="flex flex-col">
                    <p className="text-sm font-medium">{label}</p>
                    <p className="text-2xl font-bold">{value}</p>
                </div>
                <div className="text-3xl">{icon}</div>
            </div>
        );
    };

    return (
        <MainLayout>
            <div className="rounded-2xl pt-10 px-6 relative bg-star-light3 min-h-screen">
                <div className="flex items-center justify-between mb-10">
                    {/* Dropdown and Date Filters */}
                    <div className="px-2 py-2 flex items-center space-x-4">
                        <select
                            className="px-4 py-2 rounded-md border bg-white focus:outline-none focus:ring-2 focus:ring-star-default"
                            id="dropdown"
                            value={source}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Select an option</option>
                            <option value={1}>Twitter</option>
                            <option value={2}>LinkedIn</option>
                        </select>

                        <div className="flex space-x-4">
                            <input
                                type="date"
                                id="startDate"
                                value={startDate}
                                onChange={handleStartDateChange}
                                className="border rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-star-default"
                            />
                            <input
                                type="date"
                                id="endDate"
                                value={endDate}
                                onChange={handleEndDateChange}
                                className="border rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-star-default"
                            />
                        </div>
                    </div>
                </div>

                {/* Loading State */}
                {isLoading && (
                    <div className="text-center my-10">
                        <p className="text-lg text-gray-500">Loading data...</p>
                    </div>
                )}

                {/* Display Admin Stats */}
                {!isLoading && adminStats && (
                    <div className="w-full bg-gradient-to-br from-gray-50 to-gray-100 p-6 rounded-2xl border border-gray-200 shadow-sm">
                        <p className="text-xl font-semibold text-gray-700 mb-6">Post Stats</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            <StatisticCard
                                label="Scraped Posts"
                                value={adminStats.data.scrapedPost || 0}
                                icon="📥"
                                bgColor="bg-blue-100"
                                textColor="text-blue-600"
                            />
                            <StatisticCard
                                label="Synced Posts"
                                value={adminStats.data.syncedPost || 0}
                                icon="🔄"
                                bgColor="bg-green-100"
                                textColor="text-green-600"
                            />
                            <StatisticCard
                                label="Total Synced Posts"
                                value={adminStats.data.totalSyncedPost || 0}
                                icon="✅"
                                bgColor="bg-purple-100"
                                textColor="text-purple-600"
                            />
                            <StatisticCard
                                label="Total Users"
                                value={adminStats.data.totalUsers || 0}
                                icon="👥"
                                bgColor="bg-yellow-100"
                                textColor="text-yellow-600"
                            />
                            <StatisticCard
                                label="Last Synced"
                                value={
                                    adminStats.data.lastSynced
                                        ? moment(adminStats.data.lastSynced).fromNow()
                                        : "--"
                                }
                                icon="⏳"
                                bgColor="bg-gray-100"
                                textColor="text-gray-600"
                            />
                            <StatisticCard
                                label="Account Scraped"
                                value={adminStats.data.accountScraped || 0}
                                icon="👥"
                                bgColor="bg-orange-100"
                                textColor="text-orange-600"
                            />
                        </div>
                    </div>


                )}

                {/* User Profile Stats */}
                <UserProfileStats source={source} />
            </div>

        </MainLayout>
    );
};

export default AdminPage;
