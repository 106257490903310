export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const GOOGLE_CLIENT_KEY = process.env.REACT_APP_GOOGLE_CLIENT_KEY;
export const INTERCOM_KEY = process.env.REACT_APP_INTERCOM_KEY;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;
export const ONESIGNAL_KEY = process.env.REACT_APP_ONESIGNAL_KEY;
export const LINKEDIN_TOPIC_SCRAPPER_BASE_URL = process.env.REACT_APP_LINKEDIN_TOPIC_SCRAPPER;
export const TWITTER_TOPIC_SCRAPPER_BASE_URL = process.env.REACT_APP_TWITTER_TOPIC_SCRAPPER;


