import React, { useState } from 'react';
import MasonaryLayout from "../../component/MasonaryLayout";
import { EditPencil, Trash } from 'iconoir-react';
import { useQuery } from '@tanstack/react-query';
import { deleteTemplate, fetchTemplates } from '../../services/template.service';
import { toast } from 'react-toastify';

function TemplateCard({ template, onDeleteClick, onEditClick }) {
    return (
        <div className="border max-w-xl h-fit rounded-lg shadow-md p-4 bg-white">
            <div className='flex justify-between items-center'>
                {/* Template Name */}
                <h2 className="text-xl font-bold text-gray-800 mb-3 flex-1">{template.name}</h2>
                <button
                    className='cursor-pointer hover:text-star-light mr-4'
                    onClick={() => onEditClick({
                        id: template._id,
                        templateid: template.templateId,
                        name: template.name,
                        body: template.content,
                        example: template.contentExample,
                        type: template.type
                    })}
                >
                    <EditPencil />
                </button>
                <button
                    className="text-red-600 hover:text-red-900"
                    onClick={() => onDeleteClick(template._id)} // Trigger delete modal with the template ID
                >
                    <Trash className="h-5 w-5" />
                </button>
            </div>

            {/* Body */}
            <div className="mt-3">
                <p className="text-sm font-bold text-gray-600 mb-1">Body:</p>
                <p className="bg-gray-100 p-2 rounded text-sm text-gray-700 whitespace-pre-wrap">
                    {template.content}
                </p>
            </div>

            {/* Example */}
            <div className="mt-3">
                <p className="text-sm font-bold text-gray-600 mb-1">Example:</p>
                <p className="bg-gray-100 p-2 rounded text-sm text-gray-700 whitespace-pre-wrap">
                    {template.contentExample}
                </p>
            </div>
        </div>
    );
}

function TemplateList({ templates, onDeleteClick, onEditClick }) {
    return (
        templates.length > 0 ? (
            <MasonaryLayout>
                {
                    templates.map((template) => (
                        <div className="flex-1" key={template._id}>
                            <TemplateCard template={template} onDeleteClick={onDeleteClick} onEditClick={onEditClick} />
                        </div>
                    ))
                }
            </MasonaryLayout>
        ) : (
            <p className="text-gray-600 text-center">No templates available.</p>
        )
    );
}

function DeleteConfirmationModal({ isOpen, onClose, onConfirm, isDeleting }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                <h3 className="text-lg font-bold mb-4">Confirm Deletion</h3>
                <p className="text-gray-700 mb-6">Are you sure you want to delete this template? This action cannot be undone.</p>
                <div className="flex justify-end space-x-4">
                    <button
                        className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                        onClick={onClose}
                        disabled={isDeleting} // Disable button while deleting
                    >
                        Cancel
                    </button>
                    <button
                        className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                        onClick={onConfirm}
                        disabled={isDeleting} // Disable button while deleting
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default function App({ onEditTemplate, selectedTemplateType, onPostCount }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // const { data: templates = [], refetch } = useQuery({
    //     queryKey: ['templates'],
    //     queryFn: fetchTemplates,
    // });

    const { data: templates = [], refetch } = useQuery({
        queryKey: ['templates', selectedTemplateType],
        queryFn: () => fetchTemplates(selectedTemplateType),
    });

    // Open modal for delete confirmation
    const handleDeleteClick = (id) => {
        setSelectedTemplateId(id);
        setIsModalOpen(true);
    };

    const handleEditClick = (data) => {
        onEditTemplate(data)
    };

    // Close modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedTemplateId(null);
    };

    // Confirm delete
    const handleConfirmDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteTemplate({ templateid: selectedTemplateId });
            toast.success("Template deleted.");
            // Optimistic update: Remove the template from the local list
            refetch();
        } catch (error) {
            console.error("Error deleting template:", error);
            toast.error("Failed to delete template. Please try again.");
        } finally {
            setIsDeleting(false);
            handleCloseModal();
        }
    };

    return (
        <div className="p-6">
            <TemplateList templates={templates} onDeleteClick={handleDeleteClick} onEditClick={handleEditClick} onPostCount={onPostCount(templates.length)} />
            <DeleteConfirmationModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDelete}
                isDeleting={isDeleting}
            />
        </div>
    );
}
